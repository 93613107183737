import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './types';
import netlifyIdentity from 'netlify-identity-widget';

// Load user
export const authenticate = () => async (dispatch) => {
  if (netlifyIdentity.currentUser) {
    console.log(`Loading current user ${netlifyIdentity.currentUser}`);
    dispatch({
      type: USER_LOADED,
      payload: netlifyIdentity.currentUser,
    });
  } else {
    console.log(`Could not find current user.`);
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

/// Login user
export const login = () => async (dispatch) => {
  netlifyIdentity.open('login');

  netlifyIdentity.on('login', (user) => {
    if (user) {
      console.log(`Found user ${user}`);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: user,
      });

      dispatch(setAlert(`Welcome back ${user.name}!`, 'green'));
    } else {
      console.log(
        `authenticate error - Could not find current user and couldn't login.`
      );
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  });
};

// Signup user
export const signup = () => async (dispatch) => {
  netlifyIdentity.open('login');

  netlifyIdentity.on('signup', (user) => {
    if (user) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: user,
      });
      dispatch(setAlert('Thank you for registering', 'green'));
    } else {
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  });
};

// Log out
export const logout = () => async (dispatch) => {
  console.log(`Logging out`);

  netlifyIdentity.on('logout', () => {
    console.log('User logged out');
    dispatch({
      type: LOGOUT,
    });

    dispatch(setAlert('You successfully logged out', 'yellow'));
  });
};

// Delete account
export const deleteAccount = () => async (dispatch) => {
  console.log('Account deleted');
};

// Update account
export const updateAccount = () => async (dispatch) => {
  console.log('Account updated');
};
