import React from 'react';
import { Link } from 'gatsby';

import facebook from '../../img/social/facebook.svg';
import instagram from '../../img/social/instagram.svg';
import twitter from '../../img/social/twitter.svg';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='flex justify-between border-t-2 border-yellow-700 py-6 mt-8 px-2'>
        <div className='flex justify-between w-1/2'>
          <Link
            to='/'
            className='flex space-x-4 items-center justify-center'
            title='Logo'
          >
            <img
              className='h-auto w-10 items-center'
              src={'/../img/logo.png'}
              alt='petrolytics'
            />
            <div className='space-y-0'>
              <span className='block text-base font-large text-lg font-black text-white items-center hover:text-yellow-700'>
                petrolytics
              </span>
              <span className='block text-sm font-bold text-white'>
                {' '}
                distilled #energy insights
              </span>
            </div>
          </Link>
        </div>
        <div className='grid grid-flow-col auto-cols-auto gap-4 place-self-center'>
          <div className='grid grid-flow-row auto-rows-auto gap-4 hidden lg:flex'>
            <ul>
              <li className='li-footer'>
                <Link
                  to='/'
                  className='text-base text-white hover:text-yellow-700 font-black'
                >
                  home
                </Link>
              </li>
              <li className='li-footer'>
                <Link
                  className='text-base text-white hover:text-yellow-700 font-black'
                  to='/about'
                >
                  about
                </Link>
              </li>
              <li className='li-footer'>
                <Link
                  className='text-base text-white hover:text-yellow-700 font-black'
                  to='/app'
                >
                  data room
                </Link>
              </li>
            </ul>
          </div>
          <div className='grid grid-flow-row auto-rows-auto gap-4 hidden lg:flex'>
            <ul>
              <li className='li-footer'>
                <Link
                  className='text-base font-medium text-white hover:text-yellow-700 font-black'
                  to='/blog'
                >
                  latest stories
                </Link>
              </li>
              <li className='li-footer'>
                <Link
                  className='text-base font-medium text-white hover:text-yellow-700 font-black'
                  to='/privacy'
                >
                  privacy policy
                </Link>
              </li>
              <li className='li-footer'>
                <Link
                  className='text-base font-medium text-white hover:text-yellow-700 font-black'
                  to='/contact'
                >
                  contact
                </Link>
              </li>
            </ul>
          </div>
          <div className='grid grid-rows-3 gap-4 px-4 place-items-end'>
            <a title='facebook' href='https://facebook.com'>
              <img src={facebook} alt='Facebook' className='img-social' />
            </a>
            <a title='twitter' href='https://twitter.com/petrolytics_io'>
              <img src={twitter} alt='Twitter' className='img-social' />
            </a>
            <a title='instagram' href='https://instagram.com'>
              <img src={instagram} alt='Instagram' className='img-social' />
            </a>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
