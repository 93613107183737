import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import menu from '../../img/menu.svg';
import Alert from '../status/Alert';
import { setAlert } from '../../actions/alert';
import { authenticate, login, logout, signup } from '../../actions/auth';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false,
      auth: null,
    };
  }

  toggleHamburger = () => {
    this.setState({
      hamburgerActive: !this.state.hamburgerActive,
    });
  };

  render() {
    return (
      <nav>
        <Alert />
        <div className='flex justify-between border-b-2 border-yellow-700 py-6 mb-8 px-2'>
          <div className='block w-full justify-between'>
            <div className='flex justify-between w-full'>
              <Link
                to='/'
                className='flex space-x-4 items-center justify-center'
                title='Logo'
              >
                <img
                  className='h-auto w-10 items-center'
                  src={'/../img/logo.png'}
                  alt='petrolytics'
                />
                <div className='space-y-0'>
                  <span className='block text-base font-large text-lg font-black text-white items-center hover:text-yellow-700'>
                    petrolytics
                  </span>
                  <span className='block text-sm font-bold text-white'>
                    {' '}
                    distilled #energy insights
                  </span>
                </div>
              </Link>
              {/* Hamburger menu */}
              <div className='block space-x-12 lg:hidden'>
                <button onClick={() => this.toggleHamburger()}>
                  <img
                    className='h-auto w-10 items-center'
                    src={menu}
                    alt='menu'
                  />
                </button>
              </div>
            </div>
            {this.state.hamburgerActive === true ? (
              <Fragment>
                <div className='bg-gray-600 w-full rounded-xl mx-auto justify-center text-center py-2 lg:hidden'>
                  <div className='grid grid-rows-6 mx-auto w-5/6 divide-y divide-white'>
                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/about'
                    >
                      about
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/blog'
                    >
                      blog
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/app'
                    >
                      data room
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/contact'
                    >
                      contact
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/blog'
                    >
                      latest stories
                    </Link>

                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/privacy'
                    >
                      privacy policy
                    </Link>

                    <Link
                      className='text-lg text-white hover:text-yellow-700 font-black py-2'
                      to='/contact'
                    >
                      contact
                    </Link>
                  </div>
                </div>
              </Fragment>
            ) : (
              ''
            )}
          </div>
          <Fragment>
            {/* Navigation menu */}
            <div className='flex w-full justify-end space-x-12 items-center hidden lg:flex'>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-yellow-700 font-black'
                to='/about'
              >
                about
              </Link>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-yellow-700 font-black'
                to='/blog'
              >
                blog
              </Link>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-yellow-700 font-black'
                to='/app'
              >
                data room
              </Link>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-yellow-700 font-black'
                to='/contact'
              >
                contact
              </Link>
            </div>
          </Fragment>
        </div>
      </nav>
    );
  }
};

Navbar.propTypes = {
  hamburgerActive: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  authenticate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  authenticate,
  login,
  logout,
  signup,
  setAlert,
})(Navbar);
