import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../seo/SiteMetadata';
import { withPrefix } from 'gatsby';
import Footer from './Footer';
import Navbar from './Navbar';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel='icon'
          type='image/png'
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes='32x32'
        />
        <link
          rel='icon'
          type='image/png'
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes='16x16'
        />

        <link
          rel='mask-icon'
          href={`${withPrefix('/')}img/safari-pinned-tab.png`}
          color='#ff4400'
        />
        <meta name='theme-color' content='#fff' />

        <meta property='og:type' content='business.business' />
        <meta property='og:title' content={title} />
        <meta property='og:url' content='/' />
        <meta
          property='og:image'
          content={`${withPrefix('/')}img/og-image.png`}
        />
      </Helmet>
      <div className='flex-auto mx-auto bg-gray-800 bg-hero-pattern overflow-auto h-screen'>
        <div className='flex flex-col max-w-full mx-auto px-4 h-screen'>
          <Navbar />
          <div className='flex-grow'>{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TemplateWrapper;
